<template>
  <van-nav-bar
    fixed
    placeholder
    safe-area-inset-top
    :border="false"
    :class="$route['meta']['isTabbar'] ? 'istabbar-navbar' : 'notabbar-navbar'"
    :title="title"
    :left-arrow="showLeftarrow"
    @click-left="back"
  >
    <template #left v-if="!showLeftarrow">
      <slot name="left"></slot>
    </template>
    <template #right>
      <slot name="right"></slot>
    </template>
  </van-nav-bar>
</template>
  
  <script>
import { reactive, toRefs, ref } from "vue";
import Common from "@/library/Common";
import { useRouter } from "vue-router";
export default {
  name: "ClassEvaluation",
  props: {
    title: {
      default: "",
      type: String,
    },
    showLeftarrow: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    const common = Common;
    const router = useRouter();
    let state = reactive({
      lang: {
        title: props.title,
      },
      showLeftarrow: props.showLeftarrow,
    });
    const back = () => {
      if (!state.showLeftarrow) return;
      router.back(1);
    };

    return {
      ...toRefs(state),
      common,
      back,
    };
  },
};
</script>
  
  <style lang="less" scoped>
.istabbar-navbar {
  /deep/.van-nav-bar {
    background: #a5907e;
  }
  /deep/.van-nav-bar__title {
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 50px;
  }
}
.notabbar-navbar {
  /deep/.van-nav-bar {
    background: #f6f7fb;
  }
  /deep/.van-nav-bar__title {
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 50px;
  }
}
</style>