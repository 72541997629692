<template>
  <div class="commentupdate container">
    <Van-Navbar :showLeftarrow="true" :title="lang.tab_personreview"> </Van-Navbar>
    <div class="content">
      <van-cell-group inset>
        <van-field v-model="feedback.content" rows="2" autosize type="textarea" maxlength="500" show-word-limit
          :placeholder="lang.placeholder_student" />
      </van-cell-group>
      <div class="uploadlist">
        <van-uploader :upload-icon="'plus'" accept="image/*" :before-read="beforeReadImg" :after-read="afterReadImg"
          v-model="imageList" max-count="9" multiple :max-size="10 * 1024 * 1024" @oversize="onOversizeImg"
          image-fit="contain" :before-delete="deleteImg" />
      </div>
      <div class="uploadfile">
        <div class="uploadfile_item" v-for="(file, index) in feedback.uploadedList" :key="index">
          <div class="title">{{ file.name }}</div>
          <van-icon name="cross" color="#a5907e" size="22" @click="deleteUploadedFile(file)" />
        </div>

        <van-uploader max-count="1" accept="application" compressed="false" v-model="uploadFile"
          :max-size="10 * 1024 * 1024" @oversize="oversizeUploadedFile" :before-read="beforeReadUploadedFile"
          :after-read="afterReadUploadedFile">
          <van-button plain type="primary">{{
            lang.uploadpdforword
          }}</van-button>
        </van-uploader>
      </div>
      <div class="uploadfile">
        <van-uploader max-count="1" accept="video" multiple="false" compressed="false" preview-image="false"
          result-type="dataUrl" v-model="uploadVideo" :max-size="500 * 1024 * 1024" @oversize="VideoOversize"
          :before-read="VideobeforeRead" :after-read="VideoafterRead">
          <van-button plain type="primary">
            {{ lang.uploadVideo }} {{ videos.length }}</van-button>
        </van-uploader>
      </div>
      <div v-if="videos.length > 0" class="right">
        <div v-for="video in videos" :key="video">
          <van-icon color="#000" size="20px" name="cross" @click="deleteVideo(video)" />
          <div :id="video.url" style="width: 100%;height: 200px;"></div>
          <!-- <video style="width: 100%" controls autoplay preload="auto" webkit-playsinline="true" playsinline="true"
            x-webkit-airplay="allow" x5-video-player-type="h5" x5-video-player-fullscreen="true"
            x5-video-orientation="portraint" :src="video.url">
            <source :src="video.url" type="video/mp4" />
              <source :src="video.url" type="video/ogg" />
              您的浏览器不支持Video标签。
          </video> -->
        </div>
      </div>
    </div>
    <div class="footer_btn">
      <van-button type="primary" @click="confirmComments">{{
        lang.submit
      }}</van-button>
    </div>
  </div>
</template>
    
<script>
import { reactive, toRefs, onMounted, ref, watch } from "vue";
import Common from "@/library/Common";
import Config from "@/library/Config";
import request from "@/library/MyRequest";
import VanNavbar from "@/components/common/van-navbar";
import { useRouter } from "vue-router";
import { Dialog, Toast } from "vant";
import Player from 'xgplayer';
import 'xgplayer/dist/index.min.css';
export default {
  name: "ClassEvaluation",
  components: {
    VanNavbar,
  },
  props: {},
  setup(props) {
    const mediaType = {
      IMAGE: 1, //Image
      VIDEO: 2, //Video
    };
    const common = Common;
    const router = useRouter();
    const courseData = JSON.parse(router.currentRoute.value.query.courseData);
    let { courseId, studentId, had_feedback } = courseData;
    const lesson_id = String(courseId);
    const student_id = studentId;
    const imageList = ref([]);
    const uploadFile = ref([]);
    const uploadVideo = ref([]);
    let state = reactive({
      lang: {
        langBtn: "",
        personal_evaluation: "",
        pictureSize: "",
        justUpload: "",
        uploading: "",
        fill_in_class_rating: "",
        justUploadjob: "",
        DeleteThisJob: "",
        JobUploaded: "",
        publish: "",
        select_file: "",
        submitSuccess: "",
        filenametoolong: "",
        uploadpdforword: "",
        submit: "",
        placeholder_student: "",
        confirm_comments: "",
        yes: "",
        no: "",
        tab_personreview: "",
        uploadVideo: "",
        DeleteVideo: "",
        isDeleteVideo: "",
        justUploadVideo: "",
        confirm: "",
        cancel: "",
        timeout: "",
        save:""
      },
      imageList: [],
      content: "",
      placeholder: "",
      feedback: {
        content: "",
        uploadedList: [],
      },
      uploadVideo: "",
      videos: [],
      videosObject: {}
    });
    common.initLanguage(state.lang);
    // watch(props, (newProps) => {
    //   resetVedio(newProps);
    // });
    const onOversizeImg = () => {
      Toast(state.lang.pictureSize);
    };

    const resetVedio = function () {
      state.videos.map((video) => {
        state.videosObject[video.url] = new Player({
          id: video.url,
          url: video.url,
          width: '100%',
          height: '200px',
        });
      })
    }
    const beforeReadImg = (File) => {
      // const chenkFile = function(file){
      //     return file.type == "image/png" || file.type == "image/jpeg";
      // }
      console.log(File);
      if (File.length > 0) {
        //多文件
        console.log("上传多个文件");
        // for(let item of File){
        //     if(!chenkFile(item)){
        //         Toast(state.lang.justUpload);
        //         return false;
        //     }
        // }
      } else {
        //单文件
        console.log("上传单文件");
        // if(!chenkFile(File)){
        //    Toast(state.lang.justUpload);
        //     return false;
        // }
      }
      return true;
    };
    const afterReadImg = async (event) => {
      console.log(event);
      const uploadImage = async (file) => {
        var uploadFile = {
          url: null,
          name: null,
          media_type: mediaType.IMAGE,
          error: null,
        };
        const formData = new FormData();
        formData.append("file", file);
        try {
          var res = await request.upload(formData);
          if (res.status == 200) {
            uploadFile.url = res.data.url;
            uploadFile.name = file.name;
            return uploadFile;
          } else {
            uploadFile.error = `upload images https res.status:${res.status}`;
          }
          return;
        } catch (e) {
          uploadFile.error = e.message;
          console.log(e.message);
        }
        return uploadFile;
      };
      Toast.loading({
        message: state.lang.uploading,
        forbidClick: true,
        overlay: true,
      });
      if (event.length > 0) {
        for (let item of event) {
          imageList.value.pop();
        }
        for (let item of event) {
          let uploadFile = await uploadImage(item.file);
          if (uploadFile.error == null) {
            imageList.value.push(uploadFile);
          } else {
            Toast.clear();
            Toast(uploadFile.error);
          }
        }
      } else {
        imageList.value.pop();
        let uploadFile = await uploadImage(event.file);
        if (uploadFile.error == null) {
          imageList.value.push(uploadFile);
        } else {
          Toast.clear();
          Toast(uploadFile.error);
        }
      }
      Toast.clear();
    };
    const deleteImg = function (proxy) {
      return true;
    };

    const oversizeUploadedFile = function () {
      Toast(state.lang.justUploadjob);
    };
    const hasFile = function (filename) {
      for (var file of state.feedback.uploadedList) {
        if (file.name == filename) {
          return true;
        }
      }
      return false;
    };
    const beforeReadUploadedFile = (File) => {
      if (File.name.length > 100) {
        Toast(state.lang.filenametoolong);
        return false;
      } else if (hasFile(File.name)) {
        Toast(state.lang.JobUploaded);
        return false;
      }
      if (
        File.type ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        File.type == "application/msword" ||
        File.type == "application/pdf"
      ) {
        return true;
      } else {
        Toast(state.lang.justUploadjob);
        return false;
      }
    };
    const afterReadUploadedFile = async (event) => {
      console.log("afterReadUploadedFile");
      uploadFile.value.splice(0, 1);
      Toast.loading({
        message: state.lang.uploading,
        forbidClick: true,
        overlay: true,
      });
      const file = event.file;
      const formData = new FormData();
      formData.append("file", file);
      try {
        var uploadRes = await request.upload(formData);
        if (uploadRes.status == 200) {
          var uploadfile = {
            name: file.name,
            url: uploadRes.data.url,
          };
          state.feedback.uploadedList.push(uploadfile);
        }
      } catch (e) {
        Toast(e.message);
        console.log(e.message);
      }

      Toast.clear();
    };
    const deleteUploadedFile = function (item) {
      Dialog.confirm({
        title: state.lang.DeleteThisJob,
        confirmButtonText: state.lang.yes,
        cancelButtonText: state.lang.no,
      }).then(async () => {
        state.feedback.uploadedList.splice(
          state.feedback.uploadedList.indexOf(item),
          1
        );
      });
    };
    const confirmComments = () => {
      Dialog.confirm({
        title: state.lang.confirm_comments,
        confirmButtonText: state.lang.yes,
        cancelButtonText: state.lang.no,
      }).then(async () => {
        submit();
      });
    };
    const submit = async () => {
      Toast.loading({
        message: state.lang.uploading,
        forbidClick: true,
        overlay: true,
      });

      var params = {
        lesson_id: lesson_id,
        clear_all_files: true,
        admission_no: student_id,
        info: state.feedback.content,
        images: imageList.value.map((item) => {
          return { name: "", url: item.url };
        }),
        files: state.feedback.uploadedList.map((item) => {
          return item;
        }),
        videos: state.videos
      };
      try {
        var res = await request.post(`lesson/feedback-personal/`, params);
        Toast.clear();
        if (res.status == 201) {
          Toast({
            forbidClick: true,
            message: state.lang.submitSuccess,
            onClose: () => { },
          });
          router.back();
          // router.push({
          //   path: "/comment/student",
          //   query: {
          //     courseData: JSON.stringify({
          //       courseId: lesson_id,
          //       currentPath: "/course",
          //     }),
          //   },
          // });
        }
      } catch (e) {
        Toast.clear();
        Toast(e.message);
        console.log(e.message);
      }
    };

    const getFeedback = async () => {
      Toast.loading({
        message: state.lang.uploading,
        forbidClick: true,
        overlay: true,
      });
      try {
        var res = await request.get(
          `lesson/feedback-personal/?lesson_id=${lesson_id}&admission_no=${student_id}&force=1`
        );
        if (res.status == 200) {
          let data = res.data;
          state.feedback.content = data.info;
          state.feedback.uploadedList = data.files;
          imageList.value = data.images.map((item) => {
            return { url: item };
          });
          state.videos = data.videos.map((item) => {
            return { url: item };
          });
          setTimeout(() => {
            resetVedio()
          }, 1000);
        }
      } catch (e) {
        console.log(e.message);
      }
      Toast.clear();
    };
    const VideoOversize = () => {
      Toast(state.lang.videoSize);
    };

    const VideobeforeRead = (File) => {
      if (File.type == "video/mp4" || File.type == "video/quicktime") {
        return true;
      } else {
        Toast(state.lang.justUploadVideo);
        return false;
      }
    };

    const VideoafterRead = async (event) => {
      Toast.loading({
        message: state.lang.uploading,
        forbidClick: true,
        overlay: true,
        duration: 0,
      });
      const formData = new FormData();
      formData.append("file", event.file);
      try {
        var res = await request.upload(formData);
        if (res.status == 200) {
          var uploadFile = {
            name: "",
            url: res.data.url,
            // media_type: mediaType.VIDEO,
          };
          state.videos.push(uploadFile);
          setTimeout(() => {
            state.videosObject[res.data.url] = new Player({
              id: res.data.url,
              url: res.data.url,
              width: '100%',
              height: '200px',
            });
          }, 1000);
        }
      } catch (e) {
        Toast(state.lang.timeout);
        console.log(e);
      }
      uploadVideo.value = [];
      Toast.clear();
    };
    const deleteVideo = (video) => {
      Dialog.confirm({
        title: state.lang.DeleteVideo,
        confirmButtonText: state.lang.confirm,
        cancelButtonText: state.lang.cancel,
      }).then(() => {
        var index = state.videos.indexOf(video);
        state.videos.splice(index, 1);
        state.videosObject[video].destroy()
        delete state.videosObject[video]
      });
    };

    onMounted(async () => {
      console.log("getFeedback", had_feedback);
      if (had_feedback) {
        await getFeedback();
      }
    });
    return {
      ...toRefs(state),
      common,
      imageList,
      uploadFile,
      uploadVideo,
      onOversizeImg,
      beforeReadImg,
      afterReadImg,
      deleteImg,
      oversizeUploadedFile,
      beforeReadUploadedFile,
      afterReadUploadedFile,
      deleteUploadedFile,
      submit,
      confirmComments,
      VideoOversize,
      VideobeforeRead,
      VideoafterRead,
      deleteVideo,
      hasFile,
      // resetVedio
    };
  },
};
</script>
    
<style lang="less" scoped>
.commentupdate {
  // padding: 30px 20px;
  box-sizing: border-box;

  .content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }

  /deep/.van-cell-group {
    padding: 0;
    margin: 0;
  }

  /deep/.van-field__control {
    height: 300px !important;
  }

  /deep/.van-field {
    padding: 24px;
  }

  /deep/.van-cell {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 44px;
  }

  /deep/.van-uploader__upload {
    width: 180px;
    height: 180px;
    background: #eaeaea;
  }

  /deep/.van-uploader__preview {
    width: 180px;
    height: 180px;
    background: #eaeaea;
  }

  /deep/.van-icon-plus {
    font-size: 80px;
    color: #666666;
  }

  /deep/.van-uploader__preview-image {
    width: 180px;
    height: 180px;
  }

  /deep/.van-uploader__preview-delete {
    width: 50px;
    height: 50px;
  }

  /deep/.van-uploader__preview-delete-icon {
    font-size: 48px;
  }

  .uploadlist {
    margin-top: 40px;
    text-align: left;
  }

  .uploadfile {
    margin-top: 30px;

    /deep/.van-uploader,
    .van-uploader__wrapper {
      width: 100%;
    }

    /deep/.van-uploader__input-wrapper {
      width: 100%;

      .van-button {
        width: 100%;
        height: 88px;
        line-height: 88px;
        border-radius: 20px;
        font-size: 32px;
      }
    }

    .uploadfile_item {
      width: 100%;
      height: 88px;
      line-height: 88px;
      border-radius: 20px;
      font-size: 32px;
      border: 1px solid #a5907e;
      margin-bottom: 30px;
      color: #a5907e;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      box-sizing: border-box;

      .title {
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .footer_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 20px 20px;
    background-color: #fff;
    box-shadow: 0px -2px 7px 0px rgba(0, 0, 0, 0.15);

    /deep/.van-button {
      width: 100%;
      height: 88px;
      border-radius: 12px;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 44px;
      padding: 20px 40px;
      background-color: #a5907e;
      color: #fff;
      border-color: #a5907e;
    }
  }
}
</style>